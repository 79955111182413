import {
  START_DISABLED_DATES_BEFORE,
  START_FROM_MONTH,
  START_TO_MONTH,
} from '@/constants/booking-flow';
import { SearchInputState } from '@/hooks/use-search-input-state';
import useTranslation from '@/hooks/use-translation';
import { formatDateTime } from '@/utils/date-formatter';
import { TimePickerProps } from '@drivekyte/web-components';
import useSearchContext from '../../../../context/use-search-context';
import WhenInput from '../when-input';

export type StartDateProps = {
  active: boolean;
  onChange: (
    nextSelectedDate: Date | undefined,
    origin: 'date-picker' | 'time-picker',
  ) => void;
  onChangeInputState: (newState: Partial<SearchInputState>) => void;
  startDate: Date;
  timePickerProps: Pick<TimePickerProps, 'options' | 'loading' | 'tooltip'>;
  disabled: boolean;
  hasNoOptions: boolean;
};

const StartDate = ({
  active,
  onChange,
  onChangeInputState,
  startDate,
  timePickerProps,
  disabled,
  hasNoOptions,
}: StartDateProps) => {
  const { t } = useTranslation('pages.home-page.hero.search.when.start-date');

  const searchContext = useSearchContext();

  const handleActive = () => {
    onChangeInputState({ showStartDate: true });
    searchContext.onInputFocus('start-date');
  };

  const handleDeactivate = () => {
    onChangeInputState({ showStartDate: false });
    searchContext.onInputBlur();
  };

  return (
    <WhenInput
      active={active}
      datePickerProps={{
        disabledDays: START_DISABLED_DATES_BEFORE,
        fromMonth: START_FROM_MONTH,
        initialMonth: startDate,
        toMonth: START_TO_MONTH,
      }}
      formattedValue={
        hasNoOptions ? t('empty-state') : formatDateTime(startDate)
      }
      label={t('label')}
      onSelect={onChange}
      popoverProps={{
        offset: [8, 12],
        onRequestToClose: handleDeactivate,
        onRequestToOpen: handleActive,
        visible: active,
      }}
      timePickerProps={{
        loading: timePickerProps.loading,
        options: timePickerProps.options,
        titleText: t('time-picker.title'),
        tooltip: timePickerProps.tooltip,
      }}
      value={startDate}
      testID="start-date-picker"
      disabled={disabled}
    />
  );
};

export default StartDate;
