import { RentalSearchHistory } from '@/types/rental-search-history';

export const createWebURL = (
  history: RentalSearchHistory,
  formatISOWithoutTimezone: (date: Date) => string,
) => {
  const formattedStartDate = formatISOWithoutTimezone(
    new Date(history.startDate),
  );
  const formattedEndDate = formatISOWithoutTimezone(new Date(history.endDate));
  const startTz = history.startTz;
  const endTz = history.endTz;
  const vehicleClass = history.vehicleClass ?? '';
  const quoteToken = history.quoteToken ?? '';
  const isStartAddressPickupAtLot = history.isStartAddressPickupAtLot;
  const isEndAddressPickupAtLot = history.isEndAddressPickupAtLot;

  const basePath = process.env.NEXT_PUBLIC_BASE_URL;
  const path = vehicleClass?.length > 0 ? '/checkout' : '/car-picker';
  const isSameDeliveryReturnLocations =
    history.startAddress === history.endAddress;

  const queryParams = new URLSearchParams({
    start_address: history.startAddress,
    start_address_exclusion_zone_id: history.startAddressExclusionZoneId ?? '',
    end_address: history.endAddress,
    end_address_exclusion_zone_id: history.endAddressExclusionZoneId ?? '',
    start_place: history.startPlace,
    end_place: history.endPlace,
    start_date: formattedStartDate,
    end_date: formattedEndDate,
    start_timezone: startTz,
    end_timezone: endTz,
    vehicle_class: vehicleClass,
    quotes_token: quoteToken,
    is_start_address_pickup_at_lot: isStartAddressPickupAtLot?.toString() ?? '',
    is_end_address_pickup_at_lot: isEndAddressPickupAtLot?.toString() ?? '',
    service_area_code: history.serviceAreaCode,
    service_area_uuid: history.serviceAreaUUID,
    end_service_area_code: history.endServiceAreaCode,
    end_service_area_uuid: history.endServiceAreaUUID,
    same_delivery_and_return: isSameDeliveryReturnLocations.toString(),
  });

  return `${basePath}${path}?${queryParams.toString()}` as string;
};
