import { usePostHog } from 'posthog-js/react';
import PosthogFlags from '@/types/posthog-flags';

export type EnableRecentSearchesResponse = {
  enabled: boolean;
};

const useEnableRecentSearches = (): {
  data: EnableRecentSearchesResponse | null;
} => {
  const posthog = usePostHog();
  const isTreatmentVariant =
    posthog.getFeatureFlag(PosthogFlags.EnableRecentSearches) == 'treatment';

  if (isTreatmentVariant) {
    return {
      data: {
        enabled: true,
      },
    };
  }

  return {
    data: {
      enabled: false,
    },
  };
};

export default useEnableRecentSearches;
