import { GOOGLE_MAPS_BASE_URL } from '@/config/global-constants';

export const getGoogleMapsUrlByAddress = (address: string) => {
  const queryParam = encodeURIComponent(address);
  return `${GOOGLE_MAPS_BASE_URL}&query=${queryParam}`;
};

export const getGoogleMapsUrlByCoordinates = (lat: number, lng: number) => {
  return `${GOOGLE_MAPS_BASE_URL}&query=${lat},${lng}`;
};
