import React from 'react';
import { BottomSheet, Box, Button } from '@drivekyte/web-components';
import SearchBox from '@/components/search-box';
import useBookingCart from '@/hooks/use-booking-cart';
import usePartnerReferral from '@/hooks/use-partner-referral';
import useTranslation from '@/hooks/use-translation';

type SearchBottomSheetProps = {
  visible: boolean;
  onClose: () => void;
};

const SearchBottomSheet = ({ visible, onClose }: SearchBottomSheetProps) => {
  const { t } = useTranslation('pages.checkout.booking-overview.search-dialog');
  const { hasToRefineAddress } = usePartnerReferral();

  const { endAddress, endDate, endTz, startAddress, startDate, startTz } =
    useBookingCart();

  const submitDisabled =
    !endAddress ||
    !endDate ||
    !endTz ||
    !startAddress ||
    !startDate ||
    !startTz ||
    hasToRefineAddress;

  return (
    <BottomSheet onClose={onClose} open={visible} title={t('title')}>
      <SearchBox>
        <Box marginTop="small">
          <Button fullWidth disabled={submitDisabled} onPress={onClose}>
            {t('cta')}
          </Button>
        </Box>
      </SearchBox>
    </BottomSheet>
  );
};

export default SearchBottomSheet;
