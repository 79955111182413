import { Place } from '@drivekyte/use-places-search';

const mapAutocompleteData = (data?: Place[]) =>
  data?.map((item: Place) => ({
    id: `${item.lat}:${item.lng}`,
    titleText: item.primary_text,
    subtitleText: item.secondary_text,
    value: item.display_text,
    extraData: item,
    serviceAreaUUID: item.service_area_uuid,
    serviceAreaCode: item.service_area_code,
  })) || [];

export default mapAutocompleteData;
