import useTranslation from '@/hooks/use-translation';
import { Box, Popover } from '@drivekyte/ui';
import {
  DatePickerProps,
  DateTimePicker,
  EmptyState,
  TimePickerProps,
} from '@drivekyte/web-components';
import { MouseEvent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SearchInput from '../../../search-input';

export type WhenInputProps = {
  active: boolean;
  datePickerProps: Omit<DatePickerProps, 'onSelect' | 'selectedDate'>;
  formattedValue: string;
  label: string;
  onSelect: (
    nextSelectedDate: Date | undefined,
    origin: 'date-picker' | 'time-picker',
  ) => void;
  popoverProps: {
    offset: [number, number];
    onRequestToClose: () => void;
    onRequestToOpen: () => void;
    visible: boolean;
  };
  timePickerProps: Pick<
    TimePickerProps,
    'titleText' | 'options' | 'loading' | 'tooltip'
  >;
  value: Date;
  testID?: string;
  disabled?: boolean;
};

const WhenInput = ({
  datePickerProps,
  formattedValue,
  label,
  onSelect,
  popoverProps,
  timePickerProps,
  value,
  testID,
  disabled,
}: WhenInputProps) => {
  const { t } = useTranslation('components.search');
  const inputRef = useRef<HTMLInputElement>(null);
  const isActive = popoverProps.visible;
  const optionsCount = timePickerProps.options.length;

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus();
    }
  }, [isActive]);

  const handleOpenPopover = (event?: MouseEvent) => {
    event?.preventDefault();
    if (!disabled) popoverProps.onRequestToOpen();
  };

  const renderOptionsEmptyState = () => (
    <EmptyStateContainer>
      <EmptyState
        descriptionText={t('errors.address-without-number.description')}
        illustrationName="empty-state"
        titleText={t('errors.address-without-number.title')}
      />
    </EmptyStateContainer>
  );

  return (
    <Box width={160} opacity={disabled ? 0.5 : 1} borderRadius={36}>
      <Popover
        placement="bottom"
        offset={popoverProps.offset}
        onRequestToClose={popoverProps.onRequestToClose}
        renderContent={() => (
          <Box padding="$xLarge">
            <DateTimePicker
              datePickerProps={datePickerProps}
              onSelect={onSelect}
              selectedDate={value}
              testID={testID}
              timePickerProps={{
                loading: timePickerProps.loading,
                titleText: timePickerProps.titleText,
                options: timePickerProps.options,
                renderEmptyState: optionsCount
                  ? undefined
                  : renderOptionsEmptyState,
              }}
            />
          </Box>
        )}
        visible={popoverProps.visible}
        width="632px"
        zIndex={99}
        containerStyle={{
          borderRadius: 36,
          paddingTop: '$small',
        }}
      >
        <SearchInput
          label={label}
          inputOptions={{
            value: formattedValue,
            id: `${label}-id`,
          }}
          onFocus={handleOpenPopover}
          onPressLabel={handleOpenPopover}
          isFocused={isActive}
          style={{ cursor: 'pointer' }}
        />
      </Popover>
    </Box>
  );
};

const EmptyStateContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
  zoom: 0.8;
`;

export default WhenInput;
