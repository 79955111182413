import { createWebURL } from '../create-web-url/create-web-url';

export const formatFetchedSearchHistory = (
  searchData,
  formatISOWithoutTimezone,
) => {
  const updatedHistory = searchData.map((search) => {
    const history = {
      startAddress: search.start_address,
      startAddressExclusionZoneId: search.start_address_exclusion_zone_id,
      endAddress: search.end_address,
      endAddressExclusionZoneId: search.end_address_exclusion_zone_id,
      startPlace: search.start_place,
      endPlace: search.end_place,
      startDate: search.start_date,
      endDate: search.end_date,
      startTz: search.start_tz,
      endTz: search.end_tz,
      vehicleClass: search.vehicle_class,
      quoteToken: search.quote_token,
      isStartAddressPickupAtLot: search.is_start_address_pickup_at_lot,
      isEndAddressPickupAtLot: search.is_end_address_pickup_at_lot,
      serviceAreaCode: search.service_area_code,
      serviceAreaUUID: search.service_area_uuid,
      endServiceAreaCode: search.end_service_area_code,
      endServiceAreaUUID: search.end_service_area_uuid,
      timestamp: search.timestamp,
      url: '',
    };
    return {
      ...history,
      url: createWebURL(history, formatISOWithoutTimezone),
    };
  });

  return updatedHistory;
};

export const formatSearchHistoryPayload = (searchHistory) => {
  return searchHistory.map((search) => {
    return {
      start_address: search.startAddress,
      start_address_exclusion_zone_id: search.startAddressExclusionZoneId,
      end_address: search.endAddress,
      end_address_exclusion_zone_id: search.endAddressExclusionZoneId,
      start_place: search.startPlace,
      end_place: search.endPlace,
      start_date: search.startDate,
      end_date: search.endDate,
      start_tz: search.startTz,
      end_tz: search.endTz,
      vehicle_class: search.vehicleClass || '',
      quote_token: search.quoteToken || '',
      is_start_address_pickup_at_lot: search.isStartAddressPickupAtLot,
      is_end_address_pickup_at_lot: search.isEndAddressPickupAtLot,
      timestamp: search.timestamp,
      service_area_code: search.serviceAreaCode,
      service_area_uuid: search.serviceAreaUUID,
      end_service_area_code: search.endServiceAreaCode,
      end_service_area_uuid: search.endServiceAreaUUID,
    };
  });
};
