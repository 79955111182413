import request, { ApiResponse, RequestError } from '@/utils/request';
import { useMutation } from 'react-query';
import { RentalSearchHistory } from '@/types/rental-search-history';
import { formatSearchHistoryPayload } from '@/utils/format-search-history';

type SendRentalSearchHistoryType = {
  searchHistory: RentalSearchHistory[];
  uuid: string;
};

const sendRentalSearchHistory = async ({
  searchHistory,
  uuid,
}: SendRentalSearchHistoryType) => {
  const { data: response } = await request<ApiResponse<RentalSearchHistory>>({
    method: 'PUT',
    url: `v1/users/${uuid}/search-history`,
    data: {
      search_history: formatSearchHistoryPayload(searchHistory),
    },
  });

  return response.data;
};

const useSendRentalSearchHistory = () => {
  return useMutation<
    RentalSearchHistory,
    RequestError,
    SendRentalSearchHistoryType
  >(sendRentalSearchHistory);
};

export default useSendRentalSearchHistory;
