import React, { useEffect, useRef, useState } from 'react';
import { Box, MapMarker } from '@drivekyte/web-components';
import GoogleMap from 'google-map-react';
import styled from 'styled-components';
import getMapBounds from '@/utils/google-maps/get-map-bounds';

type HandoverOutpostMapProps = {
  markerTitle: string;
  markerContent: string;
  handoverAddressLatitude: number;
  handoverAddressLongitude: number;
};

const HandoverOutpostMap = ({
  markerTitle,
  markerContent,
  handoverAddressLatitude,
  handoverAddressLongitude,
}: HandoverOutpostMapProps) => {
  const DEFAULT_COORDS = { lat: 0, lng: 0 };
  const [coordinates, setCoordinates] = useState(DEFAULT_COORDS);
  const mapsRef = useRef(null);

  const bounds = getMapBounds(
    [
      {
        latitude: handoverAddressLatitude,
        longitude: handoverAddressLongitude,
      },
    ],
    mapsRef?.current,
  );

  const { lat, lng } = bounds?.getCenter().toJSON() ?? DEFAULT_COORDS;

  useEffect(() => {
    setCoordinates({ lat, lng });
  }, [lat, lng]);

  const handleMapApiLoaded = (map: any, maps: any) => {
    mapsRef.current = maps;
    setCoordinates({ lat, lng });
  };

  return (
    <Box width="100%" height="100%" testID="handover-outpost-map-container">
      <GoogleMap
        bootstrapURLKeys={{
          key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
        }}
        defaultCenter={DEFAULT_COORDS}
        center={coordinates}
        defaultZoom={12}
        onGoogleApiLoaded={({ map, maps }) => handleMapApiLoaded(map, maps)}
        yesIWantToUseGoogleMapApiInternals
      >
        <MarkerContainer
          lat={handoverAddressLatitude}
          lng={handoverAddressLongitude}
        >
          <MapMarker subtitleText={markerContent} titleText={markerTitle} />
        </MarkerContainer>
      </GoogleMap>
    </Box>
  );
};

const MarkerContainer = styled.div<{ lat: number; lng: number }>``;

export default HandoverOutpostMap;
