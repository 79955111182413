import { StackProps } from '@tamagui/core';
import { Popover as TgPopover } from '@tamagui/popover';
import React, { useRef } from 'react';
import useClickAway from 'react-use/lib/useClickAway';
import useKeyPressEvent from 'react-use/lib/useKeyPressEvent';
import { Box } from '../box';

export type Alignment = 'start' | 'end';
export type Side = 'top' | 'right' | 'bottom' | 'left';
export type AlignedPlacement = `${Side}-${Alignment}`;
export type Placement = Side | AlignedPlacement;

export type PopoverProps = {
  onRequestToClose: () => void;
  children: React.ReactNode;
  containerStyle: StackProps;
  height?: React.CSSProperties['height'];
  offset?: [number, number];
  placement?: Placement;
  renderContent: () => React.ReactNode;
  testID?: string;
  visible: boolean;
  width?: React.CSSProperties['width'];
  zIndex?: number;
};

export const Popover = ({
  onRequestToClose,
  children,
  containerStyle = {},
  height,
  offset,
  placement,
  renderContent,
  testID,
  visible,
  width,
  zIndex,
}: PopoverProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useClickAway(containerRef, () => {
    if (visible) onRequestToClose();
  });

  useKeyPressEvent('Escape', () => {
    if (visible) onRequestToClose();
  });

  // Fix SEO issue with empty popovers
  if (!visible) return <>{children}</>;

  return (
    <TgPopover
      open={visible}
      placement={placement}
      offset={{ mainAxis: offset?.[0], crossAxis: offset?.[1] }}
    >
      <TgPopover.Trigger>
        <Box width="100%">{children}</Box>
      </TgPopover.Trigger>
      <TgPopover.Content zIndex={zIndex} ref={containerRef}>
        <TgPopover.Arrow />
        <Box
          backgroundColor="$backgroundPrimary"
          borderRadius="$xxLarge"
          shadowColor={'rgba(0, 0, 0, 0.14)'}
          shadowOffset={{ width: 0, height: 1 }}
          shadowRadius={8}
          height={height}
          testID={testID}
          width={width}
          {...containerStyle}
        >
          {renderContent()}
        </Box>
      </TgPopover.Content>
    </TgPopover>
  );
};
