import { useDateFormatter } from '@drivekyte/date-utils';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import useCalendarValidDates from '@/hooks/use-calendar-valid-dates';
import useSearch from '@/hooks/use-search';
import { createTimePickerOptions } from '@drivekyte/web-components';
import Divider from '../divider';
import EndDate from './components/end-date';
import StartDate from './components/start-date';

type WhenProps = {};

const When = ({}: WhenProps) => {
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();
  const { formatISOWithoutTimezone } = useDateFormatter();

  const {
    bookingCart,
    inputState,
    isLoadingTimeSlots,
    onChange,
    onChangeEndDate,
    onChangeStartDate,
    setInputState,
    timeSlotsData,
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    timeSlotsStartError,
    timeSlotsEndError,
  } = useSearch({ disableQuoteFetching: true });

  useCalendarValidDates({
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    startTz: timeSlotsData?.start.tz,
    endTz: timeSlotsData?.end.tz,
    selectedDates: bookingCart,
    onChange,
  });

  const handleChangeDate = (
    nextSelectedDate: Date | undefined,
    origin: 'date-picker' | 'time-picker',
    key: 'start' | 'end',
  ) => {
    if (nextSelectedDate) {
      trackEvent(AnalyticsEvents.BookingFlow.SearchDateSubmitted, {
        date: formatISOWithoutTimezone(nextSelectedDate),
        is_start_leg: key === 'start',
        is_end_leg: key === 'end',
      });
    }

    if (key === 'start') {
      onChangeStartDate(nextSelectedDate, origin);
    }
    if (key === 'end') {
      onChangeEndDate(nextSelectedDate, origin);
    }
  };

  return (
    <>
      <StartDate
        active={!timeSlotsStartError && inputState.showStartDate}
        onChange={(nextSelectedDate, origin) =>
          handleChangeDate(nextSelectedDate, origin, 'start')
        }
        onChangeInputState={setInputState}
        startDate={bookingCart.startDate}
        timePickerProps={{
          loading: isLoadingTimeSlots,
          options:
            timeSlotsData?.start.timeSlots ||
            createTimePickerOptions(7, 22, bookingCart.startDate, '2-digit'),
        }}
        disabled={timeSlotsStartError}
        hasNoOptions={timeSlotsData?.start.timeSlots.length === 0}
      />
      <Divider />
      <EndDate
        active={!timeSlotsEndError && inputState.showEndDate}
        endDate={bookingCart.endDate}
        onChange={(nextSelectedDate, origin) =>
          handleChangeDate(nextSelectedDate, origin, 'end')
        }
        onChangeInputState={setInputState}
        startDate={bookingCart.startDate}
        timePickerProps={{
          loading: isLoadingTimeSlots,
          options:
            timeSlotsData?.end.timeSlots ||
            createTimePickerOptions(7, 22, bookingCart.endDate, '2-digit'),
        }}
        disabled={timeSlotsEndError}
        hasNoOptions={timeSlotsData?.end.timeSlots.length === 0}
      />
    </>
  );
};

export default When;
