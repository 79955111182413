import { Box } from '../../../box';
import { Typography } from '../../../typography';

export type AutocompleteEmptyStateProps = {
  descriptionText: string;
  testID?: string;
  titleText: string;
};

export const AutocompleteEmptyState = ({
  descriptionText,
  testID,
  titleText,
}: AutocompleteEmptyStateProps) => {
  return (
    <Box testID={testID} margin="xLarge">
      <Box marginBottom="xSmall">
        <Typography color="primary" variant="headingMedium">
          {titleText}
        </Typography>
      </Box>
      <Box marginBottom="base">
        <Typography color="primary" variant="paragraphSmall">
          {descriptionText}
        </Typography>
      </Box>
    </Box>
  );
};
