import { useRouter } from 'next/router';
import useSubscriptionCart from '@/hooks/use-subscription-cart';

const useBreadcrumbsRedirect = () => {
  const router = useRouter();
  const { startDate, toQuery } = useSubscriptionCart();

  const handleRedirect = (pathname: string) => {
    const query = toQuery();

    const nextQuery = {
      ...query,
      start_date: startDate.toISOString(),
    };

    void router.push({
      pathname,
      query: nextQuery,
    });
  };

  return handleRedirect;
};

export default useBreadcrumbsRedirect;
