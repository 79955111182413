import useBookingCart from '@/hooks/use-booking-cart';
import useTranslation from '@/hooks/use-translation';
import { useDateFormatter } from '@drivekyte/date-utils';
import { Box, Illustration, Illustrations } from '@drivekyte/web-components';
import Image from 'next/image';
import { useRouter } from 'next/router';

type LogoProps = {
  name?: Illustrations;
  height?: number;
  width?: number;
};

const Logo = ({ name = 'kyte-logo', height = 44, width = 64 }: LogoProps) => {
  const router = useRouter();
  const { formatISOWithoutTimezone } = useDateFormatter();
  const {
    code,
    endAddress,
    endDate,
    endTz,
    endPlace,
    isSameDeliveryReturnLocations,
    startAddress,
    startDate,
    startTz,
    startPlace,
    startAddressExclusionZoneId,
    endAddressExclusionZoneId,
  } = useBookingCart();
  const { t } = useTranslation('components');

  const handlePress = () => {
    router.push({
      pathname: '/',
      query: {
        code,
        end_address: endAddress,
        end_date: formatISOWithoutTimezone(endDate),
        end_timezone: endTz,
        end_place: endPlace,
        same_delivery_and_return: isSameDeliveryReturnLocations,
        start_address: startAddress,
        start_date: formatISOWithoutTimezone(startDate),
        start_timezone: startTz,
        start_place: startPlace,
        start_address_exclusion_zone_id: startAddressExclusionZoneId,
        end_address_exclusion_zone_id: endAddressExclusionZoneId,
      },
    });
  };

  return (
    <Box cursor="pointer" onPress={handlePress}>
      <Illustration
        renderImg={(src: string) => (
          <Image
            src={src}
            height={height}
            width={width}
            alt={t('logo')}
            style={{ objectFit: 'contain' }}
          />
        )}
        name={name as Illustrations}
      />
    </Box>
  );
};

export default Logo;
