import useBookingCart from '@/hooks/use-booking-cart';
import useRentalSearchStore from '@/hooks/use-rental-search-store';
import useSearch from '@/hooks/use-search';
import { RentalSearchHistory } from '@/types/rental-search-history';
import { createWebURL } from '@/utils/create-web-url';
import { useDateFormatter } from '@drivekyte/date-utils';
import dayjs from 'dayjs';
import useMe from '../use-me';
import useSendRecentSearchHistory from '../use-send-rental-search-history';

const useRentalSearchHistory = () => {
  const bookingCart = useBookingCart();
  const { searchHistory, set } = useRentalSearchStore();
  const { isFormValid } = useSearch({
    disableQuoteFetching: true,
    disableTimeslotFetching: true,
  });
  const { formatISOWithoutTimezone } = useDateFormatter();
  const { me } = useMe();

  const { mutate: sendRentSearchHistory } = useSendRecentSearchHistory();

  const createSearchData = (
    vehicleClass = '',
    quotesToken = '',
  ): RentalSearchHistory => {
    return {
      startAddress: bookingCart.startAddress,
      startAddressExclusionZoneId: bookingCart.startAddressExclusionZoneId,
      endAddress: bookingCart.endAddress,
      endAddressExclusionZoneId: bookingCart.endAddressExclusionZoneId,
      startPlace: bookingCart.startPlace ?? '',
      endPlace: bookingCart.endPlace ?? '',
      startDate: bookingCart?.startDate.getTime() ?? '',
      endDate: bookingCart?.endDate.getTime() ?? '',
      startTz: bookingCart?.startTz ?? '',
      endTz: bookingCart?.endTz ?? '',
      vehicleClass,
      quoteToken: quotesToken,
      url: '',
      isStartAddressPickupAtLot: !!bookingCart.isStartAddressPickupAtLot,
      isEndAddressPickupAtLot: !!bookingCart.isEndAddressPickupAtLot,
      serviceAreaCode: bookingCart.serviceAreaCode || '',
      serviceAreaUUID: bookingCart.serviceAreaUUID || '',
      endServiceAreaCode: bookingCart.endServiceAreaCode || '',
      endServiceAreaUUID: bookingCart.endServiceAreaUUID || '',
      timestamp: dayjs().valueOf(),
    };
  };

  const isSearchExistsInCache = (history: RentalSearchHistory) => {
    const searchExists = bookingCart.startAddress === history.startAddress;
    return searchExists;
  };

  const getSameSearchIndex = () => {
    const historyIndex = searchHistory?.findIndex((history) => {
      return isSearchExistsInCache(history);
    });
    return historyIndex;
  };

  const addURLToSearchData = (searchData: RentalSearchHistory) => {
    const url = createWebURL(searchData, formatISOWithoutTimezone);
    return { ...searchData, url };
  };

  const updateSearchHistory = (searchData: RentalSearchHistory) => {
    const historyIndex = getSameSearchIndex();
    const searchExists = historyIndex !== -1;

    const updatedHistory =
      searchExists || searchHistory?.length > 0
        ? [
            searchData,
            ...searchHistory.filter(
              (history: RentalSearchHistory) => !isSearchExistsInCache(history),
            ),
          ]
        : [searchData, ...searchHistory];

    if (updatedHistory.length > 5) {
      updatedHistory.pop();
    }
    set(updatedHistory);

    if (me?.uuid) {
      sendRentSearchHistory({
        searchHistory: [searchData],
        uuid: me.uuid,
      });
    }
  };

  const saveSearchHistory = () => {
    if (!isFormValid) return;

    const searchData = createSearchData();
    const searchDataWithURL = addURLToSearchData(searchData);

    updateSearchHistory(searchDataWithURL);
  };

  const updateVehicleClassInHistory = (
    vehicleClass: string,
    quotesToken?: string,
  ) => {
    const searchData = createSearchData(vehicleClass, quotesToken);

    const searchDataWithURL = addURLToSearchData(searchData);

    updateSearchHistory(searchDataWithURL);
  };

  const fetchSearchHistory = () => ({
    searchHistory,
    totalHistoryItems: searchHistory?.length ?? 0,
  });

  const rePrioritizeSearchHistory = (index: number) => {
    const updatedHistory = [...searchHistory];
    if (index === 0 || updatedHistory?.length < 1) return;

    const history = updatedHistory.splice(index, 1)[0];
    const tempHistory = { ...history, timestamp: dayjs().valueOf() };

    updatedHistory.unshift(tempHistory);
    set(updatedHistory);

    if (me?.uuid) {
      sendRentSearchHistory({
        searchHistory: updatedHistory,
        uuid: me.uuid,
      });
    }
  };

  return {
    saveSearchHistory,
    updateVehicleClassInHistory,
    fetchSearchHistory,
    rePrioritizeSearchHistory,
    updateCachedSearchHistory: set,
  };
};

export default useRentalSearchHistory;
