export const enum NavbarVariantColorEnum {
  DARK = 'dark',
  LIGHT = 'light',
}

export const enum NavbarVariantStateEnum {
  SIMPLE = 'simple',
  SEARCH = 'search-bar',
  DEFAULT = 'default',
}
