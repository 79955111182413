import { END_FROM_MONTH, END_TO_MONTH } from '@/constants/booking-flow';
import { SearchInputState } from '@/hooks/use-search-input-state';
import useTranslation from '@/hooks/use-translation';
import { formatDateTime } from '@/utils/date-formatter';
import { TimePickerProps } from '@drivekyte/web-components';
import dayjs from 'dayjs';
import useSearchContext from '../../../../context/use-search-context';
import WhenInput from '../when-input';

export type EndDateProps = {
  active: boolean;
  endDate: Date;
  onChange: (
    nextSelectedDate: Date | undefined,
    origin: 'date-picker' | 'time-picker',
  ) => void;
  onChangeInputState: (newState: Partial<SearchInputState>) => void;
  startDate: Date;
  timePickerProps: Pick<TimePickerProps, 'options' | 'loading' | 'tooltip'>;
  disabled?: boolean;
  hasNoOptions: boolean;
};

const EndDate = ({
  active,
  endDate,
  onChange,
  onChangeInputState,
  startDate,
  timePickerProps,
  disabled,
  hasNoOptions,
}: EndDateProps) => {
  const { t } = useTranslation('pages.home-page.hero.search.when.end-date');
  const searchContext = useSearchContext();

  const handleActive = () => {
    onChangeInputState({ showEndDate: true });
    searchContext.onInputFocus('end-date');
  };

  const handleDeactivate = () => {
    onChangeInputState({ showEndDate: false });
    searchContext.onInputBlur();
  };

  const handleOnChange = (
    nextSelectedDate: Date | undefined,
    origin: 'date-picker' | 'time-picker',
  ) => {
    onChange(nextSelectedDate, origin);
    if (origin === 'time-picker') handleDeactivate();
  };

  return (
    <WhenInput
      active={active}
      datePickerProps={{
        disabledDays: [{ before: dayjs(startDate).toDate() }],
        fromMonth: END_FROM_MONTH,
        initialMonth: endDate,
        toMonth: END_TO_MONTH,
      }}
      formattedValue={hasNoOptions ? t('empty-state') : formatDateTime(endDate)}
      label={t('label')}
      onSelect={handleOnChange}
      popoverProps={{
        offset: [8, 12],
        onRequestToClose: handleDeactivate,
        onRequestToOpen: handleActive,
        visible: active,
      }}
      timePickerProps={{
        loading: timePickerProps.loading,
        options: timePickerProps.options,
        titleText: t('time-picker.title'),
        tooltip: timePickerProps.tooltip,
      }}
      value={endDate}
      disabled={disabled}
    />
  );
};

export default EndDate;
