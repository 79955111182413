import { Place } from '@drivekyte/use-places-search';
import { HandoverOutpostType } from '@/hooks/use-suggested-handover';

export const getAnalyticsData = (
  requestedPlace: Place,
  suggestedPlace: Place,
  leg_type: HandoverOutpostType,
) => {
  return {
    exception_name: suggestedPlace?.exclusion_zone?.name,
    exception_market: requestedPlace?.service_area_code,
    submitted_address: requestedPlace?.formatted_address,
    submitted_geocode: `${requestedPlace?.lat},${requestedPlace?.lng}`,
    leg_type,
  };
};
