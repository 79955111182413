import { Switch as TmgSwitch } from '@tamagui/switch';

export type SwitchProps = {
  value: boolean;
  defaultValue?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  onChange: (value: boolean) => void;
};

export const Switch = ({
  value,
  defaultValue,
  disabled,
  size = 'medium',
  onChange,
}: SwitchProps) => {
  const frameColor = () => {
    if ((value && disabled) || !value) {
      return '$backgroundNeutralSubtle';
    }
    return '$backgroundPositive';
  };

  const thumbColor = () => {
    if (disabled) {
      return '$contentInverseDisabled';
    }
    return '$white100';
  };

  return (
    <TmgSwitch
      unstyled
      checked={value}
      defaultChecked={defaultValue}
      disabled={disabled}
      onCheckedChange={onChange}
      borderRadius={28}
      width={size === 'medium' ? 52 : 36}
      height={size === 'medium' ? 32 : 24}
      padding={2}
      justifyContent="center"
      cursor="pointer"
      backgroundColor={frameColor()}
      pointerEvents={disabled ? 'none' : 'auto'}
      outlineWidth={0}
      style={{
        borderStyle: 'none',
        outlineStyle: 'none',
        outlineWidth: 0,
        borderWidth: 0,
      }}
      focusStyle={{ outlineStyle: 'none', borderWidth: 0, outlineWidth: 0 }}
      aria-label={value ? 'Switch is on' : 'Switch is off'}
    >
      <TmgSwitch.Thumb
        width={size === 'medium' ? 28 : 20}
        height={size === 'medium' ? 28 : 20}
        borderRadius={28}
        animation="fast"
        backgroundColor={thumbColor()}
        style={{
          filter:
            value || disabled
              ? 'none'
              : 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.16)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.16))',
        }}
      />
    </TmgSwitch>
  );
};
