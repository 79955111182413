import { Box, useTheme } from '@drivekyte/ui';
import { forwardRef } from 'react';
import styled from 'styled-components';

type SearchInputProps = {
  isFocused: boolean;
  label: string;
  placeholder?: string;
  inputOptions: any;
  onPressLabel: () => void;
  onFocus: () => void;
  onBlur?: () => void;
  style?: React.CSSProperties;
};

const SearchInput = forwardRef(
  (
    {
      isFocused,
      label,
      onPressLabel,
      onFocus,
      onBlur,
      placeholder,
      inputOptions,
      style,
    }: SearchInputProps,
    ref,
  ) => {
    const theme = useTheme();

    // Note: this component has a value prop but no onChange handler, resulting in a console error.

    return (
      <Box
        display="flex"
        alignItems="flex-start"
        backgroundColor={isFocused ? '$backgroundPrimary' : 'transparent'}
        paddingHorizontal="$medium"
        paddingVertical="$small"
        borderRadius={80}
      >
        <Label
          onClick={onPressLabel}
          htmlFor={inputOptions.id}
          id={inputOptions['aria-labelledby']}
          aria-label={inputOptions['aria-labelledby']}
        >
          {label}
        </Label>
        <Input
          {...inputOptions}
          placeholder={placeholder}
          style={{
            color: !inputOptions.value
              ? theme.contentSecondary.val
              : theme.contentPrimary.val,
            width: '100%',
            ...style,
          }}
          ref={ref}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Box>
    );
  },
);

const Label = styled.label`
  font-family: Hanken Grotesk;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: #101010;
  display: inline;
`;

const Input = styled.input`
  background-color: transparent;
  font-family: Hanken Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: 0px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus {
    outline: 0px;
    outline-style: 'none';
  }
`;

export default SearchInput;
