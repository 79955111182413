import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React, { MouseEvent } from 'react';
import styled from 'styled-components';

export type NavBarLinkProps = {
  active?: boolean;
  children: string | React.ReactNode;
  onPress?: (event: MouseEvent<HTMLAnchorElement>) => void;
  openNewWindow?: boolean;
  shallow?: boolean;
  testID?: string;
} & NextLinkProps;

const NavBarLink = ({
  active = false,
  children,
  href,
  onPress,
  openNewWindow,
  shallow,
  testID,
}: NavBarLinkProps) => (
  <StyledLink
    active={active.toString()}
    data-testid={testID}
    passHref
    href={href}
    onClick={onPress}
    rel={openNewWindow ? 'noopener noreferrer' : undefined}
    target={openNewWindow ? '_blank' : '_self'}
    shallow={shallow}
    aria-label={`${String(href).replace('/', '')}-link`}
  >
    {children}
  </StyledLink>
);

const StyledLink = styled(NextLink)<{
  active: string;
}>`
  margin: 0;
  padding: 0;
  text-decoration: none;
  cursor: default;
`;

export default NavBarLink;
