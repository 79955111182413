import useBookingCart from '@/hooks/use-booking-cart';
import useSelfPickup from '@/hooks/use-self-pickup';
import useVerifyAddresses from '@/hooks/use-verify-addresses';
import { useEffect } from 'react';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import { QueryBookingCart } from '@/types/booking-cart';
import { useQueryClient } from 'react-query';

const useVagueToLotRedirect = () => {
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();
  const queryClient = useQueryClient();

  const {
    updateBookingCart,
    startAddress,
    endAddress,
    serviceAreaCode,
    endServiceAreaCode,
    startPlace,
    endPlace,
    isStartAddressPickupAtLot,
    isEndAddressPickupAtLot,
  } = useBookingCart();

  const { isVagueStartAddress, isVagueEndAddress } = useVerifyAddresses({
    startAddress,
    endAddress,
  });

  const { startLotPlace, endLotPlace, isLegBasedSelfPickupEnabled } =
    useSelfPickup();

  const hasVagueAddress = isVagueStartAddress || isVagueEndAddress;

  let enableAddressToggler = true;
  const isStartAddressLot =
    startAddress === startLotPlace?.display_text ||
    startAddress === startLotPlace?.map_to?.display_text ||
    startAddress === startLotPlace?.formatted_address;
  const isEndAddressLot =
    endAddress === endLotPlace?.display_text ||
    endAddress === endLotPlace?.map_to?.display_text ||
    endAddress === endLotPlace?.formatted_address;
  const isLotAddress = isStartAddressLot || isEndAddressLot;

  // disable address toggler if leg based self pickup is disabled but the address is a lot
  if (!isLegBasedSelfPickupEnabled && isLotAddress) {
    enableAddressToggler = false;
  }

  useEffect(() => {
    if (!startLotPlace || !endLotPlace) {
      return;
    }

    if (
      !hasVagueAddress ||
      startLotPlace.service_area_code !== serviceAreaCode ||
      endLotPlace.service_area_code !== endServiceAreaCode
    ) {
      if (
        startAddress &&
        endAddress &&
        (isStartAddressPickupAtLot !== isStartAddressLot ||
          isEndAddressPickupAtLot !== isEndAddressLot)
      )
        updateBookingCart({
          is_start_address_pickup_at_lot: isStartAddressLot,
          is_end_address_pickup_at_lot: isEndAddressLot,
        });

      return;
    }

    const eventTrackingData = {
      original_start_address: startAddress,
      original_end_address: endAddress,
      start_address: startLotPlace.display_text,
      end_address: isLegBasedSelfPickupEnabled
        ? endLotPlace?.display_text
        : startLotPlace.display_text,
      is_start_address_pickup_at_lot: true,
      is_end_address_pickup_at_lot: true,
      service_area_code: startLotPlace.service_area_code,
      service_area_uuid: startLotPlace.service_area_uuid,
      end_service_area_code: isLegBasedSelfPickupEnabled
        ? endLotPlace?.service_area_code
        : startLotPlace.service_area_code,
      end_service_area_uuid: isLegBasedSelfPickupEnabled
        ? endLotPlace?.service_area_uuid
        : startLotPlace.service_area_uuid,
      start_leg_type: 'self_pickup',
      end_leg_type: 'self_pickup',
    };

    trackEvent(
      AnalyticsEvents.Search.VagueSearchRedirectedToLot,
      eventTrackingData,
    );

    let nextBookingCart: Partial<QueryBookingCart> = {};

    if (isVagueStartAddress) {
      const startAddressParams = {
        start_address: startLotPlace.display_text,
        start_place: startPlace,
        start_address_exclusion_zone_id: undefined,
        service_area_code: startLotPlace.service_area_code,
        service_area_uuid: startLotPlace.service_area_uuid,
        is_start_address_pickup_at_lot: true,
      };

      nextBookingCart = {
        ...startAddressParams,
        ...(!isLegBasedSelfPickupEnabled && {
          same_delivery_and_return: true,
          end_address: startLotPlace.display_text,
          end_place: startPlace,
          end_address_exclusion_zone_id: undefined,
          end_service_area_uuid: startLotPlace.service_area_uuid,
          end_service_area_code: startLotPlace.service_area_code,
          is_end_address_pickup_at_lot: true,
        }),
      };
    }

    if (isVagueEndAddress && isLegBasedSelfPickupEnabled) {
      const endAddressParams = {
        end_address: endLotPlace.display_text,
        end_place: endPlace,
        end_address_exclusion_zone_id: undefined,
        end_service_area_code: endLotPlace.service_area_code,
        end_service_area_uuid: endLotPlace.service_area_uuid,
        is_end_address_pickup_at_lot: true,
      };

      nextBookingCart = {
        ...nextBookingCart,
        ...endAddressParams,
      };
    }

    // cancel any ongoing quote requests
    queryClient.cancelQueries('quote');

    updateBookingCart(nextBookingCart);
  }, [
    hasVagueAddress,
    startLotPlace?.display_text,
    endLotPlace?.display_text,
    startAddress,
    endAddress,
  ]);

  return {
    enableAddressToggler,
  };
};

export default useVagueToLotRedirect;
