import { useCallback, useEffect } from 'react';
import throttle from 'lodash/throttle';

const useScroll = (callback: (position: number) => void, isActive: boolean) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    throttle(() => {
      const currentScroll = window.pageYOffset;
      callback(currentScroll);
    }, 25),
    [],
  );

  const register = () => {
    document.addEventListener('scroll', handleScroll);
  };

  const deregister = () => {
    document.removeEventListener('scroll', handleScroll);
  };

  useEffect(() => {
    if (isActive) {
      register();
    } else {
      deregister();
    }

    return () => {
      deregister();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
};

export default useScroll;
