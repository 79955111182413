import Logo from '@/components/nav/components/logo';
import { Box, useMedia } from '@drivekyte/ui';
import { Illustrations } from '@drivekyte/web-components';
import { useNavbarContext } from '../nav-bar-context';

const SimpleNavBar = () => {
  const { isDark } = useNavbarContext();
  const media = useMedia();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={media.gtMd ? '96px' : '72px'}
      backgroundColor={isDark ? '$green700' : 'primaryB'}
      paddingHorizontal="$small"
      paddingVertical="$medium"
      width="100%"
      borderBottomColor={isDark ? 'transparent' : '$borderDefault'}
      borderBottomWidth={isDark ? 0 : 1}
      borderStyle={isDark ? undefined : 'solid'}
      zIndex={10}
    >
      <Logo
        name={(isDark ? 'kyte-logo-white' : 'kyte-logo') as Illustrations}
      />
    </Box>
  );
};

export default SimpleNavBar;
