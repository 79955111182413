import { PillButton } from '@drivekyte/ui';
import NavBarLink from '../nav-bar-links/nav-bar-link';
import { useNavbarContext } from '../nav-bar-context';

const SignUpButton = () => {
  const { t, handleSignUp } = useNavbarContext();

  return (
    <NavBarLink href="/" onPress={handleSignUp}>
      <PillButton variant="primary">{t('sign-up')}</PillButton>
    </NavBarLink>
  );
};

export default SignUpButton;
