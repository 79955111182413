import { PillButton, Typography } from '@drivekyte/ui';
import NavBarLink from '../nav-bar-links/nav-bar-link';
import { useNavbarContext } from '../nav-bar-context';

const LoginButton = () => {
  const { handleSignUp, isDark, t } = useNavbarContext();
  return (
    <NavBarLink href="/" onPress={handleSignUp}>
      <PillButton
        backgroundColor={isDark ? '$green700' : 'primaryA'}
        hoverStyle={{
          backgroundColor: '$backgroundOverlayInverseTertiary',
        }}
        pressStyle={{
          backgroundColor: '$backgroundOverlayInverseSecondary',
        }}
      >
        <Typography
          variant="labelMedium"
          color={isDark ? '$contentInversePrimary' : '$primaryA'}
        >
          {t('login')}
        </Typography>
      </PillButton>
    </NavBarLink>
  );
};

export default LoginButton;
