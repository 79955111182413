import { Place } from '@drivekyte/use-places-search';
import { useQuery } from 'react-query';
import request, { RequestError } from '@/utils/request';
import {
  CACHE_DURATION_MS,
  STALE_DURATION_MS,
} from '@/constants/requests-configuration';

export type PlaceDetails = {
  map_to?: Omit<Place, 'map_to'>;
  vague?: boolean;
  service_area?: {
    uuid: string;
    code: string;
  };
  lat: number;
  lng: number;
};

const fetchPlaceDetails = async (address: string) => {
  const { data: response } = await request({
    url: 'v1/places/details',
    params: {
      search: address,
    },
  });

  return response.data;
};

const usePlaceDetails = (address: string, shouldRun = true) =>
  useQuery<PlaceDetails, RequestError>(
    ['placeDetails', address],
    () => fetchPlaceDetails(address),
    {
      enabled: shouldRun && !!address,
      cacheTime: CACHE_DURATION_MS.medium,
      staleTime: STALE_DURATION_MS.medium,
    },
  );

export default usePlaceDetails;
