import create from 'zustand';
import { persist } from 'zustand/middleware';
import { RentalSearchHistory } from '@/types/rental-search-history';

type RentalSearchStore = {
  searchHistory: RentalSearchHistory[];
  set: (nextSearchHistory: RentalSearchHistory[]) => void;
  reset: () => void;
};

const useRentalSearchStore = create<RentalSearchStore>(
  persist(
    (set) => ({
      searchHistory: [] as RentalSearchHistory[],
      set: (nextSearchHistory) => set({ searchHistory: nextSearchHistory }),
      reset: () => set({ searchHistory: [] }),
    }),
    {
      name: 'search_history',
    },
  ),
);

export default useRentalSearchStore;
