import { ALLLOWED_LOGOUT_URLS } from '@/config/global-constants';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import useMe from '@/hooks/use-me';
import useMeAuthentication from '@/hooks/use-me-authentication';
import DcsFlags from '@/types/dcs-flags';
import request, { RequestError } from '@/utils/request';
import { useMutation } from 'react-query';
import useDcsFlag from '../use-dcs-flag';
import useEnableAuth0 from '../use-enable-auth0';
import useQuery from '../use-query';

const getRedirectUrl = () => {
  const urls = ALLLOWED_LOGOUT_URLS.split(',');

  const isUrlValid = urls?.some((urlItem) => {
    const parsedUrlItem = new URL(urlItem);

    const regexPattern = parsedUrlItem.pathname.replace(/\*/g, '.*');
    const regex = new RegExp(`^${regexPattern}$`);

    return regex.test(window.location.pathname);
  });

  const nextUrl = isUrlValid ? window.location.href : window.location.origin;
  const authSignoutParam = `auth_signout=true`;

  return nextUrl.includes(`?`)
    ? `${nextUrl}&${authSignoutParam}`
    : `${nextUrl}?${authSignoutParam}`;
};

const requestLogout = async (
  enableAuth0?: boolean,
  isFlagEnabled?: boolean,
) => {
  if (enableAuth0) {
    const redirectUrl = encodeURIComponent(getRedirectUrl());
    const auth0Url = `${process?.env.NEXT_PUBLIC_API_BASE_URL}/${
      isFlagEnabled ? 'rest/auth0' : 'v2/users'
    }/logout?redirect_url=${redirectUrl}`;

    return auth0Url;
  }

  const { data: response } = await request({
    url: 'user/logout',
  });

  return response.data;
};

const useSignOut = () => {
  const { me } = useMe();
  const { clearUserData } = useMeAuthentication();
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  const { data: enableAuth0 } = useEnableAuth0();
  const { isFlagEnabled } = useDcsFlag({
    flag: DcsFlags.EnableAuthEndpoints,
  });

  const query = useQuery();
  const enableAuth0Param = query('enable_auth0', 'boolean') as boolean;

  const isAuth0Enabled = enableAuth0?.enabled || enableAuth0Param;

  return useMutation(() => requestLogout(isAuth0Enabled, isFlagEnabled), {
    onSuccess: (redirectUrl) => {
      trackEvent(AnalyticsEvents.SignInEvents.SignOutSuccess, {
        uuid: me?.uuid,
      });
      if (redirectUrl && isAuth0Enabled) {
        trackEvent(AnalyticsEvents.Auth0.SignoutRedirect);
        window.location.href = redirectUrl;
      } else {
        clearUserData();
      }
    },
    onError: (error: RequestError) => {
      trackEvent(AnalyticsEvents.SignInEvents.SignOutError, {
        uuid: me?.uuid,
        error: error?.message,
      });
    },
  });
};

export default useSignOut;
