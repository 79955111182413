import create from 'zustand';

// Define the state and actions for each input field slice
type AddressTextState = {
  addressInputValue: string;
  setAdressInputValue: (value: string) => void;
};

type AddressInputSlice = {
  [key: string]: AddressTextState;
};

// Create an individual slice for each key
const createAddressInputSlice = (key: string) => (set: any) => ({
  addressInputValue: '',
  setAdressInputValue: (value: string) =>
    set((state: AddressInputSlice) => ({
      [key]: { ...state[key], addressInputValue: value },
    })),
});

// Combine slices into the store
const createAddressInputStore = (inputKeys: string[]) => {
  return create<AddressInputSlice>((set) => {
    const initialState = inputKeys.reduce((acc, key) => {
      acc[key] = createAddressInputSlice(key)(set);
      return acc;
    }, {} as AddressInputSlice);

    return initialState;
  });
};

const useAddressInputState = createAddressInputStore([
  'start-address',
  'end-address',
]);

export default useAddressInputState;
