import React from 'react';
import { useNavbarContext } from '../nav-bar-context';
import NavBarLink from '../nav-bar-links/nav-bar-link';
import useAnalyticsWithSource from '@/hooks/use-analytics-with-source';
import AnalyticsEvents from '@/config/analytics-events';
import { PillButton, Typography } from '@drivekyte/ui';

export const MyTripsButton = () => {
  const { isDark, t } = useNavbarContext();
  const { trackEvent } = useAnalyticsWithSource();

  return (
    <NavBarLink
      href="/my-trips"
      onPress={() =>
        trackEvent(AnalyticsEvents.Navigation.NavTripsClicked, {
          hpExperimentNavUsed: true,
        })
      }
      prefetch
    >
      <PillButton
        backgroundColor={isDark ? '$green700' : 'none'}
        hoverStyle={{
          backgroundColor: '$backgroundOverlayInverseTertiary',
        }}
        pressStyle={{
          backgroundColor: '$backgroundOverlayInverseSecondary',
        }}
      >
        <Typography
          variant="labelMedium"
          color={isDark ? '$contentInversePrimary' : '$contentPrimary'}
        >
          {t('trips')}
        </Typography>
      </PillButton>
    </NavBarLink>
  );
};
