import { ThemeTokens } from '@tamagui/core';
import { BaseButton, BaseButtonProps } from '../base-button';
import { Box } from '../box';
import { LoadingIndicator } from '../loading-indicator';

export type PillButtonProps = Omit<
  BaseButtonProps,
  'height' | 'icon' | 'size'
> & { size?: 'medium' | 'small' };

const PillButton = ({
  loading,
  children,
  size,
  variant,
  state = 'default',
  disabled,
  ...props
}: BaseButtonProps) => {
  const getTextColor: () => ThemeTokens = () => {
    if (disabled) {
      return '$contentDisabled';
    }

    if (variant === 'primary') {
      return '$contentInversePrimary';
    }

    if (state === 'danger') {
      return '$contentNegative';
    }

    return '$contentPrimary';
  };

  return (
    <BaseButton
      {...props}
      alignSelf="flex-start"
      height={size === 'small' ? 36 : 48}
      borderRadius="$xLarge"
      cursor="pointer"
      size={size}
      variant={variant}
      state={state}
      disabled={disabled}
      loading={loading}
    >
      {loading && (
        <Box position="absolute">
          <LoadingIndicator />
        </Box>
      )}
      <Box
        opacity={loading ? 0 : 1}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <BaseButton.Text
          variant={size === 'small' ? 'labelSmall' : 'labelMedium'}
          color={getTextColor()}
        >
          {children}
        </BaseButton.Text>
      </Box>
    </BaseButton>
  );
};

export { PillButton };
