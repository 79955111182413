import NavBarLink from '../nav-bar-links/nav-bar-link';
import AnalyticsEvents from '@/config/analytics-events';
import useAnalyticsWithSource from '@/hooks/use-analytics-with-source';
import { PillButton } from '@drivekyte/ui';
import { useNavbarContext } from '../nav-bar-context';

const DownloadAppButton = () => {
  const { t } = useNavbarContext();
  const { trackEvent } = useAnalyticsWithSource();

  return (
    <NavBarLink
      href="/download-app"
      onPress={() =>
        trackEvent(AnalyticsEvents.Navigation.NavDownloadAppButtonClicked, {
          hpExperimentNavUsed: true,
        })
      }
      prefetch
    >
      <PillButton variant="primary">{t('download')}</PillButton>
    </NavBarLink>
  );
};

export default DownloadAppButton;
