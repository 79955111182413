import React, { useEffect, useRef } from 'react';
import useSearchContext from '@/pages/home-page/components/hero-section/components/hero-desktop/components/search/context/use-search-context';
import SearchCompactView from '@/pages/home-page/components/hero-section/components/hero-desktop/components/search/search-compact-view';
import SearchBottomSheet from '@/components/onboarding-dialog/components/search-bottom-sheet';
import { Box } from '@drivekyte/ui';
import { createPortal } from 'react-dom';
import { useSearchbarProvider } from '../desktop-nav-bar-search/search-bar-context';

const SearchBarMobile = () => {
  const { isSearchbarOpen, toggleSearchbar } = useSearchbarProvider();
  const searchContext = useSearchContext();
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        !isDatePickerOpen() &&
        !isWhereSearchOpen()
      ) {
        toggleSearchbar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        toggleSearchbar(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [searchRef]);

  const handleCompactViewPress = () => {
    searchContext.onInputFocus('start-address');
    toggleSearchbar(true);
  };

  const isDatePickerOpen = () =>
    document.querySelector('[role="dialog"]') !== null;

  const isWhereSearchOpen = () =>
    document.querySelector('[role="listbox"]') !== null;

  const mobileSearchBar = (
    <Box display="flex" justifyContent="center" alignItems="center">
      {!isSearchbarOpen && (
        <SearchCompactView onPress={handleCompactViewPress} />
      )}
      <SearchBottomSheet
        visible={isSearchbarOpen}
        onClose={() => toggleSearchbar(false)}
      />
    </Box>
  );

  return createPortal(mobileSearchBar, document.body);
};

export default SearchBarMobile;
