import React from 'react';
import { useNavbarContext } from '../nav-bar-context';
import { IconButton } from '@drivekyte/ui';
import { Menu } from '@drivekyte/icons';

type MenuButtonProps = {
  handleMenuClick: () => void;
};

export const MenuButton = ({ handleMenuClick }: MenuButtonProps) => {
  const { isDark } = useNavbarContext();

  return (
    <IconButton
      onPress={handleMenuClick}
      hoverStyle={{
        backgroundColor: isDark
          ? '$backgroundOverlayInverseTertiary'
          : '$backgroundSecondary',
        cursor: 'pointer',
      }}
      pressStyle={{
        backgroundColor: isDark
          ? '$backgroundOverlayInverseSecondary'
          : '$backgroundTertiary',
      }}
      icon={<Menu />}
      size="medium"
      variant={isDark ? undefined : 'tertiary'}
      aria-label={'menu-button'}
    />
  );
};
