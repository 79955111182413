import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import useBookingCart from '@/hooks/use-booking-cart';
import useMe from '@/hooks/use-me';
import useRentalSearchHistory from '@/hooks/use-rental-search-history';
import useTranslation from '@/hooks/use-translation';
import { useDateTransformer } from '@drivekyte/date-utils';
import { Box, Typography } from '@drivekyte/ui';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';

type RecentSearchesProps = {
  onClose?: () => void;
};

const RecentSearches = (props: RecentSearchesProps) => {
  const { fetchSearchHistory, rePrioritizeSearchHistory } =
    useRentalSearchHistory();
  const { searchHistory } = fetchSearchHistory();
  const updatedSearchHistory = searchHistory?.slice(0, 3);
  const router = useRouter();
  const { updateBookingCart, code } = useBookingCart();
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();
  const { isAuthenticated } = useMe();
  const { t } = useTranslation('pages.welcome-page.search');
  const { getDateInServiceAreaTz } = useDateTransformer();

  const handleOnPress = async (url: string, index: number) => {
    const {
      startAddress,
      endAddress,
      startDate,
      endDate,
      startTz,
      endTz,
      startPlace,
      endPlace,
      serviceAreaCode,
      serviceAreaUUID,
      endServiceAreaCode,
      endServiceAreaUUID,
    } = searchHistory[index];

    trackEvent(AnalyticsEvents.Search.RecentSearchClicked, {
      start_address: startAddress,
      start_place: startPlace,
      end_address: endAddress,
      end_place: endPlace,
      start_date: startDate,
      end_date: endDate,
      start_timezone: startTz,
      end_timezone: endTz,
      is_user_authenticated: isAuthenticated,
    });

    const startDateInTz = getDateInServiceAreaTz(startDate, startTz);
    const endDateInTz = getDateInServiceAreaTz(endDate, endTz);

    const today = dayjs().tz(startTz);

    const isFutureStartDate = dayjs(startDateInTz).isAfter(today, 'day');
    const isFutureEndDate = dayjs(endDateInTz).isAfter(today, 'day');

    if (isFutureStartDate && isFutureEndDate) {
      if (code) url = `${url}&code=${code}`;

      await router.push(url);
    } else {
      updateBookingCart({
        start_address: startAddress,
        end_address: endAddress,
        start_place: startPlace,
        end_place: endPlace,
        service_area_code: serviceAreaCode,
        service_area_uuid: serviceAreaUUID,
        end_service_area_code: endServiceAreaCode,
        end_service_area_uuid: endServiceAreaUUID,
      });
    }

    props?.onClose?.();

    rePrioritizeSearchHistory(index);
  };

  return (
    <Box gap="$small" paddingTop="$medium" $gtMd={{ paddingTop: '$none' }}>
      <Typography variant="labelXSmall" color="$contentSecondary">
        {t('recent-searches')}
      </Typography>
      {updatedSearchHistory?.map((item, index) => {
        const {
          startPlace,
          startAddress,
          startDate,
          endDate,
          vehicleClass,
          url: redirectURL,
        } = item;

        const deliveryPlace = startPlace?.length ? startPlace : startAddress;
        const isStartEndDateMonthDifferent =
          dayjs(startDate).month() !== dayjs(endDate).month();

        const dates = isStartEndDateMonthDifferent
          ? `${dayjs(startDate).format('MMM D')} - ${dayjs(endDate).format(
              'MMM D',
            )}`
          : `${dayjs(startDate).format('MMM D')} - ${dayjs(endDate).format(
              'D',
            )}`;

        return (
          <Box
            key={index}
            onPress={() => handleOnPress(redirectURL, index)}
            cursor="pointer"
          >
            <Typography variant="paragraphMedium">{deliveryPlace}</Typography>
            <Typography variant="paragraphSmall" color="$contentSecondary">
              {dates} {!!vehicleClass.length && ` • ${vehicleClass}`}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default RecentSearches;
