import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  LinkButton,
  Typography,
} from '@drivekyte/web-components';
import { useMedia } from '@drivekyte/ui';
import styled from 'styled-components';
import AnalyticsEvents from '@/config/analytics-events';
import {
  HandoverOutpostState,
  HandoverOutpostType,
} from '@/hooks/use-suggested-handover';
import useTranslation from '@/hooks/use-translation';
import useAnalyticsWithSource from '@/hooks/use-analytics-with-source';
import { getGoogleMapsUrlByAddress } from '@/utils/google-maps/get-urls';
import HandoverOutpostMap from './components/handover-outpost-map';
import { getAnalyticsData } from './utils';

type HandoverOutpostConfirmationDialogProps = {
  visible: boolean;
  handoverOutpostState: HandoverOutpostState;
  onSubmit: () => void;
  onClose: () => void;
};

const HandoverOutpostConfirmationDialog = ({
  visible,
  handoverOutpostState,
  onSubmit,
  onClose,
}: HandoverOutpostConfirmationDialogProps) => {
  const { t } = useTranslation('components.handover-outpost-dialog');
  const media = useMedia();
  const { trackEvent } = useAnalyticsWithSource();
  const isMobile = media.xs;
  const {
    requestedStart,
    requestedEnd,
    handoverOutpostStart,
    handoverOutpostEnd,
    handoverOutpostType,
  } = handoverOutpostState;

  const requestedAddress = (requestedStart || requestedEnd)!;
  const suggestedAddress = (handoverOutpostStart || handoverOutpostEnd)!;

  const isReturnOnly =
    handoverOutpostState.handoverOutpostType === HandoverOutpostType.RETURN;

  const handleMapLinkClick = () => {
    if (requestedAddress) {
      window.open(
        getGoogleMapsUrlByAddress(suggestedAddress.formatted_address),
        '_blank',
      );
    }
  };

  const handleOnSubmit = () => {
    trackEvent(
      AnalyticsEvents.HandoverOutpostEvents.HandoverOutpostAccepted,
      getAnalyticsData(
        requestedAddress,
        suggestedAddress,
        handoverOutpostType!,
      ),
    );
    onSubmit();
  };

  const exclusionZoneName = suggestedAddress?.exclusion_zone?.name;

  useEffect(() => {
    if (visible) {
      trackEvent(
        AnalyticsEvents.HandoverOutpostEvents.HandoverOutpostDisplayed,
        getAnalyticsData(
          requestedAddress,
          suggestedAddress,
          handoverOutpostType!,
        ),
      );
    }
  }, [handoverOutpostType, requestedAddress, suggestedAddress, visible]);

  return (
    <Dialog
      visible={visible}
      onRequestToClose={onClose}
      closeIconName="24-close"
      size="small"
      titleText={t('title')}
    >
      <Box width="100%" height="230px">
        <HandoverOutpostMap
          markerTitle={handoverOutpostState.handoverOutpostType!}
          markerContent={exclusionZoneName!}
          handoverAddressLatitude={suggestedAddress!.lat}
          handoverAddressLongitude={suggestedAddress!.lng}
        />
      </Box>
      <Box marginTop="base">
        <Typography color="primary" variant="headline-3">
          {exclusionZoneName}
        </Typography>
        <Typography color="primary" variant="body-1">
          {suggestedAddress!.formatted_address}
        </Typography>
      </Box>
      {!isMobile && (
        <Box display="flex" flexDirection="row">
          <LinkButton testID="handover-map-link" onPress={handleMapLinkClick}>
            {t('open-map-button')}
          </LinkButton>
        </Box>
      )}
      <Box marginTop="base">
        <Typography color="primary" variant="body-1">
          {t(
            isReturnOnly
              ? 'content-reason.part-1.return'
              : 'content-reason.part-1.delivery',
          )}{' '}
          <BoldText>{t('content-reason.part-2')}</BoldText>
          {t('content-reason.part-3')}
        </Typography>
      </Box>
      <Box marginTop="base">
        <Button fullWidth variant="primary" onPress={handleOnSubmit}>
          {t('confirm-button')}
        </Button>
      </Box>
      {isMobile && (
        <Box marginTop="small">
          <Button fullWidth variant="secondary" onPress={handleMapLinkClick}>
            {t('open-map-button')}
          </Button>
        </Box>
      )}
    </Dialog>
  );
};

const BoldText = styled.strong`
  font-weight: 700 !important;
`;

export default HandoverOutpostConfirmationDialog;
