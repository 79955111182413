import { Menu } from '@drivekyte/icons';
import { Box, Divider } from '@drivekyte/ui';
import {
  BreadcrumbItem,
  Breadcrumbs,
  Illustrations,
} from '@drivekyte/web-components';
import Logo from '@/components/nav/components/logo';
import { NavbarVariantStateEnum } from '@/constants/navbar';
import useBreadcrumbsRedirect from '@/hooks/use-breadcrumbs-redirect';
import { useSearchbarProvider } from '../desktop-nav-bar-search/search-bar-context';
import { useNavbarContext } from '../nav-bar-context';
import SearchBarMobile from './mobile-search-bar';

type MobileNavBarCollapsedProps = {
  onPress: () => void;
  subscriptionBreadcrumbs?: BreadcrumbItem[];
  top?: number;
};

const MobileNavBarCollapsed = ({
  onPress,
  subscriptionBreadcrumbs,
  top = 0,
}: MobileNavBarCollapsedProps) => {
  const handleRedirect = useBreadcrumbsRedirect();
  const { isDark, navState } = useNavbarContext();
  const { isSticky } = useSearchbarProvider();
  const isSearch = navState === NavbarVariantStateEnum.SEARCH;

  const renderMobileSearchbar = () => {
    if (isSearch || isSticky) {
      return (
        <>
          <Divider marginVertical="0" />
          <SearchBarMobile />
        </>
      );
    }
  };

  return (
    <>
      <Box
        backgroundColor={isDark ? '$green700' : 'primaryB'}
        position="relative"
        width="100%"
        justifyContent="center"
        zIndex={5}
        paddingHorizontal="$medium"
        paddingVertical="$medium"
        top={top > 0 ? top : 0}
      >
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Logo
            name={(isDark ? 'kyte-logo-white' : 'kyte-logo') as Illustrations}
          />
          <Box
            cursor="pointer"
            onPress={onPress}
            testID="navigation-collapsed-open"
          >
            <Menu
              color={isDark ? '$contentInversePrimary' : '$contentPrimary'}
            />
          </Box>
        </Box>
        {subscriptionBreadcrumbs && (
          <Box marginBottom="small">
            <Breadcrumbs
              items={subscriptionBreadcrumbs}
              onPress={handleRedirect}
            />
          </Box>
        )}
      </Box>
      {renderMobileSearchbar()}
    </>
  );
};

export default MobileNavBarCollapsed;
