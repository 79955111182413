import { useDateFormatter, useDateTransformer } from '@drivekyte/date-utils';
import { DEFAULT_START_DATE } from '@/constants/booking-flow';
import {
  LiabilityType,
  QuerySubscriptionCart,
  SubscriptionCart,
} from '@/types/subscription-cart';
import usePush from '../use-push';
import useQuery from '../use-query';

type UseSubscriptionCartType = SubscriptionCart & {
  toQuery: () => QuerySubscriptionCart;
  updateSubscriptionCart: (nextValues: Partial<QuerySubscriptionCart>) => void;
};

const useSubscriptionCart = (): UseSubscriptionCartType => {
  const { sanitizeDate } = useDateTransformer();
  const { formatISOWithoutTimezone } = useDateFormatter();
  const query = useQuery();
  const push = usePush();

  const startDateQuery = query('start_date', 'string') as string;

  const startDate = startDateQuery
    ? sanitizeDate(startDateQuery)
    : DEFAULT_START_DATE;

  const subscriptionCart = {
    startDate,
    applyCredits: true,
    code: query('code') as string,
    hasDamageCoverage: query('has_damage_coverage', 'boolean') as boolean,
    liabilityType: query('liability_type') as LiabilityType,
    hasLiabilityCoverage: (query('liability_type') as LiabilityType) === 'kyte',
    miles: query('miles') as number,
    months: query('months') as number,
    startAddress: query('start_address') as string,
    startPlace: query('start_place') as string,
    startTz: query('start_timezone') as string,
    vehicleClass: query('vehicle_class') as string,
    paymentCard: query('selected_card') as string,
    quotesToken: query('quotes_token') as string,
    isTeslaSearch: query('is_tesla_search', 'boolean') as boolean,
    serviceAreaCode: query('service_area_code') as string,
  };

  const toQuery = () => ({
    code: subscriptionCart.code,
    has_damage_coverage: subscriptionCart.hasDamageCoverage,
    liability_type: subscriptionCart.liabilityType,
    quotes_token: subscriptionCart.quotesToken,
    months: subscriptionCart.months,
    miles: subscriptionCart.miles,
    selected_card: subscriptionCart.paymentCard,
    start_address: subscriptionCart.startAddress,
    start_date: formatISOWithoutTimezone(subscriptionCart.startDate),
    start_place: subscriptionCart.startPlace,
    start_timezone: subscriptionCart.startTz,
    vehicle_class: subscriptionCart.vehicleClass,
    service_area_code: subscriptionCart.serviceAreaCode,
  });

  const updateSubscriptionCart = (nextValues: Partial<QuerySubscriptionCart>) =>
    push(nextValues);

  return {
    ...subscriptionCart,
    toQuery,
    updateSubscriptionCart,
  };
};

export default useSubscriptionCart;
