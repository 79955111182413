// google-map-react does not export a type for the maps object
const getMapBounds = (
  coordinates: { latitude: number; longitude: number }[],
  maps: any,
) => {
  if (!maps) {
    return undefined;
  }

  const bounds = new maps.LatLngBounds();

  coordinates.forEach((coordinate) => {
    bounds.extend(new maps.LatLng(coordinate.latitude, coordinate.longitude));
  });

  return bounds;
};

export default getMapBounds;
