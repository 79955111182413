/* eslint-disable react/jsx-props-no-spreading */
import { StackProps, getTokenValue, useTheme } from '@tamagui/core';
import Downshift, { GetInputPropsOptions } from 'downshift';
import React, { CSSProperties } from 'react';
import { Box } from '../box';
import { LoadingIndicator } from '../loading-indicator';
import { Typography } from '../typography';
import { AutocompleteEmptyState } from './components/autocomplete-empty-state';

export type AutoCompleteItem<T> = {
  id: string;
  subtitleText: string;
  titleText: string;
  value: string;
  extraData?: T;
};

export type AutoCompleteProps<T = any> = {
  data: AutoCompleteItem<T>[];
  customEmptyState?: React.ReactNode;
  emptyState?: {
    titleText: string;
    descriptionText: string;
    testID?: string;
  };
  listFooterComponent?: () => React.ReactNode;
  loading?: boolean;
  loadingContainerStyle?: StackProps;
  loadingTestID?: string;
  noResults?: boolean;
  onInputValueChange: (inputValue: string) => void;
  onOuterPress?: () => void;
  onSelect: (item: AutoCompleteItem<T> | null) => void;
  open: boolean;
  renderInput: (options: Omit<GetInputPropsOptions, 'as'>) => React.ReactNode;
  selectedItem?: AutoCompleteItem<T>;
  testID?: string;
  inputValue: string;
  containerStyle?: StackProps;
  itemStyle?: CSSProperties;
};

export const AutoComplete = <T = any,>({
  data,
  customEmptyState,
  containerStyle = {},
  emptyState = {
    descriptionText: 'No results were found',
    titleText: "We're not available here",
  },
  itemStyle = {},
  listFooterComponent,
  loading,
  loadingContainerStyle = {},
  loadingTestID,
  onInputValueChange,
  onOuterPress,
  onSelect = () => {},
  open,
  renderInput,
  selectedItem,
  testID,
  inputValue,
}: AutoCompleteProps<T>) => {
  const theme = useTheme();

  // For accessibility, remove unused `aria-labelledby` prop
  const comboBox = document.querySelector('[role="combobox"]');
  if (comboBox) {
    comboBox.removeAttribute('aria-labelledby');
  }

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    onInputValueChange(event.currentTarget.value);
  };

  const renderEmptyContent = () => {
    if (customEmptyState) return customEmptyState;

    return (
      <AutocompleteEmptyState
        descriptionText={emptyState.descriptionText}
        titleText={emptyState.titleText}
        testID={emptyState.testID}
      />
    );
  };

  return (
    <Box testID={testID} width="100%">
      <Downshift
        inputValue={inputValue}
        isOpen={open}
        itemToString={(item) => (item ? item.value : '')}
        onOuterClick={onOuterPress}
        onSelect={onSelect}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getRootProps,
          highlightedIndex,
          isOpen,
        }) => {
          return (
            <>
              <div {...getRootProps({}, { suppressRefError: true })}>
                {renderInput({
                  ...getInputProps(),
                  onChange: handleChange,
                })}
              </div>
              <Box
                marginTop="$tiny"
                borderWidth={isOpen ? 1 : 0}
                borderColor="$borderDefault"
                borderRadius="$small"
                position="absolute"
                width="100%"
                backgroundColor="$backgroundPrimary"
                zIndex={900}
                {...containerStyle}
                maxHeight={320}
              >
                {isOpen && (
                  <div
                    {...getMenuProps({
                      onClick: (event) => {
                        event.stopPropagation();
                      },
                    })}
                  >
                    <ul
                      style={{
                        padding: 0,
                        overflowY: 'auto',
                        borderBottomLeftRadius: getTokenValue(
                          '$small',
                          'radius',
                        ),
                        borderBottomRightRadius: getTokenValue(
                          '$small',
                          'radius',
                        ),
                        zIndex: 1,
                        width: '100%',
                        height: 216,
                      }}
                    >
                      {loading ? (
                        <Box
                          alignItems="center"
                          display="flex"
                          height="100%"
                          justifyContent="center"
                          marginVertical="xxxLarge"
                          {...loadingContainerStyle}
                        >
                          <LoadingIndicator testID={loadingTestID} />
                        </Box>
                      ) : (
                        <>
                          {data.length === 0 ? (
                            renderEmptyContent()
                          ) : (
                            <>
                              {data.map((item, index) => (
                                <li
                                  key={`${item?.value}${index}`}
                                  {...getItemProps({
                                    item: item,
                                    index: index,
                                  })}
                                  style={{
                                    backgroundColor:
                                      highlightedIndex === index ||
                                      selectedItem?.value === item.value
                                        ? theme.backgroundSecondary.val
                                        : theme.backgroundPrimary.val,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: getTokenValue(
                                      '$xSmall',
                                      'space',
                                    ),
                                    cursor: 'pointer',
                                    ...itemStyle,
                                  }}
                                >
                                  <Typography
                                    variant="paragraphMedium"
                                    color="$contentPrimary"
                                    backgroundColor="transparent"
                                  >
                                    {item.titleText}
                                  </Typography>
                                  <Typography
                                    variant="paragraphSmall"
                                    color="$contentSecondary"
                                    backgroundColor="transparent"
                                  >
                                    {item.subtitleText}
                                  </Typography>
                                </li>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </ul>
                    {listFooterComponent && listFooterComponent()}
                  </div>
                )}
              </Box>
            </>
          );
        }}
      </Downshift>
    </Box>
  );
};
