import create from 'zustand';

type SearchInputState = {
  inputFocused: string | undefined;
  onInputFocus: (inputId: string) => void;
  onInputBlur: () => void;
};

type UseSearchContextState = {
  [key: string]: SearchInputState;
};

const useSearchContextState = create<UseSearchContextState>(() => ({}));

// Custom hook to manage individual input states dynamically
const useSearchContext = (inputId: string = 'default') => {
  const contextState = useSearchContextState((state) => state[inputId]);

  if (!contextState) {
    // Initialize the slice if it doesn't exist
    useSearchContextState.setState((prevState) => ({
      ...prevState,
      [inputId]: {
        inputFocused: undefined,
        onInputFocus: (focusedId: string) => {
          useSearchContextState.setState((prev) => ({
            ...prev,
            [inputId]: { ...prev[inputId], inputFocused: focusedId },
          }));
        },
        onInputBlur: () => {
          useSearchContextState.setState((prev) => ({
            ...prev,
            [inputId]: { ...prev[inputId], inputFocused: undefined },
          }));
        },
      },
    }));
  }

  // Return the specific slice for this inputId
  return useSearchContextState((state) => state[inputId]);
};

export default useSearchContext;
